import React from "react";
import Image from 'next/image';
 

export const HeroWrapper = ({ children, dark = false, size = "md" }) => {
    const heroSize = 
    size === "lg" ? "95vh" :
        size === "md" ? "80vh" : 
            size === "sm" ? "40vh" : "";
    return (
        <>
            <div className={"hero " + (dark && "dark")}>
                <div className="hero__content">
                    {children}
                </div>
            </div>
            <style jsx>{`
            .hero {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                height: calc(${heroSize} + var(--nav-height));
                padding-top: var(--nav-height);
                min-height: max-content;
                // max-height: 900px;
                width: 100vw;
                overflow: visible;
                position: relative;
            }
            .hero.dark {
                color: #fff;
            }
            .hero__content {
                z-index:20;
                display: flex;
                flex-direction: row;
                // gap: 2rem;
                justify-content: center;
                place-items:center;
                height: 100%;
                min-height: max-content;
                padding: var(--landing-page-padding);
                max-width: var(--landing-page-max-width);
                margin: 0 auto;
                width: 100%;
            }
            @media (max-width: 768px) {
                .hero {
                    flex-direction: column;
                    height: auto;
                }
                .hero__content {
                    width: 100%;
                    padding: var(--landing-page-padding);
                }
            }
        `}</style></>

    );
};

export const HeroContent = ({ children }) => {
    return <><div>{children}</div><style jsx>
        {`
            div {
                min-width: 50%;
                z-index: 8;
            }
        `}
    </style></>;
};

export const HeroContentFull = ({ children }) => {
    return <><div>{children}</div><style jsx>
        {`
            div {
                z-index: 8;
                width: 100%;
            }
        `}
    </style></>;
};

export const HeroBackgroundVideo = ({ video, videoAlt = "" }) => {

    return (<>
        <video className="hero__vid" autoPlay muted loop playsInline>
            <source src={video} type="video/webm"/>
            <source src={videoAlt} type="video/mp4"/>
        </video>
        <style jsx>{`
        .hero__vid {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
        }
        @media (max-width: 768px) {
            .hero__vid {
                max-width: 100%;
            }
        }
        `}</style></>);
};

export const HeroBackgroundImage = ({ image, backdrop = false, mobile = false }) => {
    return (<>
        <div>
            {(backdrop && <div className="backdrop"/>)}
            <Image
                alt="hero background image"
                className="hero-img"
                src={image}
                fill
                sizes="100vw"
                style={{
                    objectFit: 'cover',
                }}
            />
        </div>
        <style jsx>{`
        .hero__vid {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -2;
            ${mobile ? "display:none;" : ""}
        }
        .backdrop{
            position:absolute;
            width:100%;
            height:100%;
            background:black;
            opacity:50%;
            top:0;
            left:0;
            z-index: -1;
            ${mobile ? "display:none;" : ""}
        }
        @media (max-width: 768px) {
            :global(.hero-img) {
                opacity: 0.1;
            }
            .hero__vid {
                max-width: 100%;
                ${mobile ? "display:block;" : ""}
            }
        }
        `}</style></>);
};

export const HeroImage = ({ image, size = "md" }) => {

    const heroSize = 
    size === "lg" ? "100rem" :
        size === "md" ? "40rem" : 
            size === "sm" ? "20rem" : "";

    return (<><Image width={500} height={500} className="hero__image" src={image.src} alt="hero_image" /><style jsx>{`
    
    :global(.hero__image) {
            width: 100%;
            max-width: ${heroSize};
            min-width:20rem;
            height: auto;
            margin: 0 auto;
            z-index: 2;
        }
        @media (max-width: 768px) {
            :global(.hero__image) {
                max-width: 100%;
                display:none;
            }
        }
        `}</style></>);
};

export const HeroTitle = ({ children, center = false, animateIn = false, noGradient = false, header = "h1" }) => {
    const condition = header === "h1";
    const HeaderText = condition ? <><h1 data-text={"Covalent Grants Program"} className={"hero__title " + (center && " center ") + (!noGradient && " gradient-text")}>{children}</h1><style jsx>{`
    .hero__title {
        font-size: var(--font-size-jumbo);
        font-weight: var(--font-weight-bold);
        margin-left: -5px;
        max-width: 60rem;
        line-height: 0.9;
        z-index: 4;
        letter-spacing: -0.05em;
        position: relative;
        ${(animateIn && `animation: animate-in-title 2s forwards;
        animation-delay: 1s;
        opacity: 0;
        `)}
    }

    @keyframes animate-in-title {
        0% {
            opacity: 0;
            transform: translateY(10%) scale(0.98);
        }
        100% {
            opacity: 1;
            transform: translateY(0%) scale(1);
        }
    }
    @keyframes animate-in-subtitle {
        0% {
            opacity: 0;
            transform: translateY(10%) scale(0.98);
        }
        100% {
            opacity: 1;
            transform: translateY(0%) scale(1);
        }
    }
    @keyframes animate-in-cta {
        0% {
            opacity: 0;
            transform: translateY(10%) scale(0.98);
        }
        100% {
            opacity: 1;
            transform: translateY(0%) scale(1);
        }
    }

    .center {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    @media (max-width: 768px) {
        .hero__title {
            font-size: var(--font-size-7);
            animation-delay: 0s;
        }
        
    }
`}</style></> : <><h2 data-text={"Covalent Grants Program"} className={"hero__title animate-in " + (center && " center ") + (!noGradient && " gradient-text")}>{children}</h2><style jsx>{`
    .hero__title {
        font-size: var(--font-size-jumbo);
        margin-left: -5px;
        max-width: 60rem;
        font-weight: var(--font-weight-bold);
        line-height: 0.9;
        z-index: 4;
        letter-spacing: -0.05em;
        position: relative;
        ${(animateIn && `animation: animate-in-title 2s forwards;
        animation-delay: 1s;
        opacity: 0;
        `)}
    }

    @keyframes animate-in-title {
        0% {
            opacity: 0;
            transform: translateY(10%) scale(0.98);
        }
        100% {
            opacity: 1;
            transform: translateY(0%) scale(1);
        }
    }
    @keyframes animate-in-subtitle {
        0% {
            opacity: 0;
            transform: translateY(10%) scale(0.98);
        }
        100% {
            opacity: 1;
            transform: translateY(0%) scale(1);
        }
    }
    @keyframes animate-in-cta {
        0% {
            opacity: 0;
            transform: translateY(10%) scale(0.98);
        }
        100% {
            opacity: 1;
            transform: translateY(0%) scale(1);
        }
    }

    .center {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
    
    .gradient-text {
        color: transparent;
    }

    .gradient-text::before {
        content: "${children}";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 110%;
        width: 100%;
        background: linear-gradient(160deg, var(--teal), var(--pink));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    @media (max-width: 768px) {
        .hero__title {
            font-size: var(--font-size-7);
            animation-delay: 0s;
            line-height: 1.2;
        }
        
    }
`}</style></>;
  
    return (
        <>
            {HeaderText}
        </>
    );
};

export const HeroSubtitle = ({ children, center = false, animateIn = false }) => {
    return (
        <p className={"hero__subtitle " + (center && " center")}>{children}
            <style jsx>{`
            .hero__subtitle {
                font-size: var(--font-size-4);
                line-height: var(--line-height-4);
                font-weight: var(--font-weight-light);
                margin-bottom: 0rem;
                margin-top: 1rem;
                max-width: 50rem;
                padding-bottom: 0.5rem;
                ${(animateIn && `animation: animate-in-subtitle 2s forwards;
                animation-delay: 2s;
                opacity: 0;
                `)}
            }

            .center {
                margin-left: auto;
                margin-right: auto;
                text-align: center;
            }
            @media (max-width: 768px) {
                .hero__subtitle {
                    animation-delay: 0.5s;
                }
                
            }
        `}</style></p>
    );
};

export const HeroBadge = ({ children }) => {
    return (
        <div className="hero__badge">{children}
            <style jsx>{`
            .hero__badge {
                margin-bottom: 1rem;
                display:flex;
                gap:0.5rem
            }
        `}</style></div>
    );
};

export const HeroCtas = ({ children, center = false, animateIn = false }) => {
    return (
        <div className={"hero__ctas animate-in " + (center && " center")}>
            {children}
            <style jsx>{`
            .center {
                margin-left: auto;
                margin-right: auto;
                text-align: center;
                width: max-content;
            }

            .hero__ctas {
                gap: 1rem;
                display: flex;
                flex-wrap: wrap;
                ${(center && "justify-content:center;")}
                max-width: 100%;
                margin-top: 1rem;
                ${(animateIn && `animation: animate-in-cta 1s forwards;
                animation-delay: 3s;
                opacity: 0;
                `)}
            }

            @media (max-width: 768px) {
                .hero__ctas {
                    animation-delay: 1s;
                }
                
            }
        `}</style></div>
    );
};

export const HeroCTA = ({ children }) => {
    return (
        <div className="hero__cta">{children}</div>
    );
};