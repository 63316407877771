import React from "react";
import ParallaxHeroContext from "./ContextProviders/ParallaxHeroContext";

interface ParallaxHeroProps {
  imageUrl: string;
  speed?: number;
  minHeight?: string;
  children?: React.ReactNode;
  video?: string;
  videoAlt?: string;
  videoCarousel?: string[];
  dark?: boolean;
}

const ParallaxHero: React.FC<ParallaxHeroProps> = ({
    imageUrl,
    children,
    video = "",
    videoAlt = "",
    videoCarousel = []
}) => {
    const context = React.useContext(ParallaxHeroContext);
    const videoRef = React.useRef<HTMLVideoElement>(null);
    const objectRefs = React.useRef<React.MutableRefObject<HTMLVideoElement | null>[]>([]);

    React.useEffect(() => {
        objectRefs.current = Array(videoCarousel.length)
            .fill(null)
            .map((_, index) => objectRefs.current[index] || React.createRef());
    }, [videoCarousel]);

    React.useEffect(() => {
        if (videoCarousel.length) {
            const videoElement = objectRefs.current?.[context.index].current;
        
            if (videoElement) {
                videoElement.load();
                videoElement.play().catch((error) => console.error('Error playing video:', error));
            }
            return () => {
                if (videoElement) {
                    videoElement.pause();
                }
            };
        }
    }, [context.index]);

    const formatBackgroundImageSetStyling = (srcSet) => {
        const imageSet = srcSet.split(",");
        const styling = `
            ${
    imageSet.map((imageSet, index) => {
        const splitArray = imageSet.split(" ");
        const image = splitArray[0];
        const size = splitArray[1]?.slice(0, -1);
        if (index === 0) {
            return `
                .parallax-hero::before {
                    background: url(${image}) no-repeat center center;
                    background-size: cover;
                }
            `;
        }
        return `
        @media (min-width: ${size}px) {
            .parallax-hero::before {
                background: url(${image}) no-repeat center center;
            }
        }`;
    }).join('')
}
        `;

        return styling;
    };

    return (
        <div className="parallax-hero">
            {
                videoCarousel.length ? videoCarousel.map((vid, i) => {
                    return <video
                        key={vid}
                        className={(i === context.index ? "show" : "hide") + " parallax_video"}
                        ref={objectRefs.current[i]}
                        autoPlay={i === context.index}
                        muted
                        loop
                        playsInline>
                        <source src={vid} type="video/webm"/>
                        <source src={vid} type="video/mp4"/>
                    </video>;
                }) : <video 
                    className="parallax_video" 
                    ref={videoRef} 
                    autoPlay 
                    muted 
                    loop 
                    playsInline
                >
                    <source src={video} type="video/webm"/>
                    <source src={videoAlt} type="video/mp4"/>
                </video>
            }
            {children}
            <style jsx>{`
                .parallax-hero {
                    position: relative;
                    overflow: hidden;
                }
                .parallax-hero::before {
                    content: "";
                    position: fixed;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background-size: cover;
                    will-change: transform;
                    z-index: -1;
                    ${videoCarousel.length && "background: var(--background);"}
                }
                @keyframes animate-in {
                    from {
                        opacity: 0;
                    }
                    to {
                        opacity: 1;
                    }
                }
                @keyframes animate-out {
                    from {
                        opacity: 1;
                    }
                    to {
                        opacity: 0;
                    }
                }
                .show {
                    animation-name: animate-in;
                    animation-duration: 1s;
                    animation-fill-mode: forwards;
                }
                .hide {
                    animation-name: animate-out;
                    animation-duration: 1s;
                    animation-fill-mode: forwards;
                }
                ${imageUrl && formatBackgroundImageSetStyling(imageUrl)}
                .parallax_video {
                    position: fixed;
                    object-fit: cover;
                    width: 100vw;
                    height: 100vh;
                    min-height: none;
                    max-height: none;
                    max-width: none;
                    top: 0%;
                    left: 0%;
                    will-change: transform;
                    z-index: -1;
                    border-radius: 0px;
                }

                @media screen and (max-width: 767px) {
                    .parallax_video {
                        display: none;
                    }
                    .parallax-hero {
                        ${!imageUrl && "background: var(--covalent-dark-blue);"}
                    }
                }
            `}</style>
        </div>
    );
};

export default ParallaxHero;
