import { createContext } from 'react';

export interface iParallaxHeroContext {
    index: number;
    setIndex: Function;
}

const ParallaxHeroContext = createContext<iParallaxHeroContext>({
    index: 0,
    setIndex: () => null
});

export default ParallaxHeroContext;
